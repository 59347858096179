<template>
  <div class="resetPassword">
    <div class="centerContent">
      <section class="formContainer">
        <div v-if="feedback === ''">
          <div class="formTop twocol">
            <h2>Forget Password</h2>
          </div>
          <div class="formBody">
            <p>
              Enter your email address and we'll send you a link to help you
              reset your password.
            </p>
            <!--  -->
            <div class="form">
              <!-- email -->
              <div class="field">
                <label class="lineLable">Email</label>

                <input
                  type="email"
                  v-model="email"
                  required
                  class="fullWidth"
                  @keyup.enter="triggerSumbit"
                />
              </div>
              <button
                class="button button--blue"
                :disabled="!email_valid"
                @click.prevent="resetPassword"
              >
                SUBMIT
              </button>

              <div class="formBody__signup">
                <router-link :to="{ name: 'login' }" class="blueTextLink"
                  >Cancel</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- form feedback -->
        <div
          class="feedback"
          :class="{ expand: feedback !== '' }"
          v-html="feedback"
        ></div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
// import * as jsUtils from "@/utils/jsUtils.js";

export default {
  data() {
    return {
      email: "",
      feedback: "",
      email_valid: false,
    };
  },
  computed: {
    formValid() {
      return this.email_valid;
      // return true;
    },
  },
  watch: {
    email() {
      this.validateEmail();
    },
  },
  methods: {
    validateEmail() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email_valid = emailFormat.test(this.email);
    },
    triggerSumbit() {
      if (this.email_valid) {
        this.resetPassword();
      }
    },
    resetPassword() {
      new Promise((resolve) => {
        wsUtils.ForgotPassword(resolve, {
          EmailId: this.email,
        });
      });

      this.restComplete();

      // pswdReset.then((data) => {
      //   this.restComplete(data);
      // });
    },
    restComplete() {
      this.feedback =
        '<h2>Thank You</h2><p>If the email entered exists in our database, an email will be sent to help you reset your password.</p><p>Please check your inbox <span class="note">(or sometimes junk folder)</span> and follow the instructions provided in order to set up a new password.</p><p class="note">For your security, the reset link can only be used once and will expire in 10 minutes. If you encounter any problems, please contact support.</p>';
    },
    backLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.resetPassword {
  background: url("../../public/images/logBG4.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  height: 100%;
  background-attachment: fixed;
  display: grid;
}

.form {
  margin: 2em 0;
}

.failBG {
  margin-bottom: 1em;
  padding: 1em;
}

.field {
  .iconInput {
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;
  }

  .iconInput:focus-within .icon {
    background-color: var(--hsblue);
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    transition: background-color 0.5s;
  }

  .icon.fixRight {
    background-color: transparent;
    color: var(--iconBGGrey);
  }

  .iconBtn {
    cursor: pointer;
  }

  .icon + input {
    transition: background-color 0.5s;
  }

  .icon + input:focus {
    background-color: var(--lightBlue);
  }
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  text-align: center;
  padding: 0 20px;
  max-height: 0;
  line-height: 1.5em;
}

.feedback.expand {
  padding: 20px;
  max-height: 90vh;
  opacity: 1;
}
</style>

<style lang="scss">
.feedback {
  .note {
    color: var(--hsblue);
  }
}
</style>
